import { createSlice } from '@reduxjs/toolkit'
import { themeConfig } from 'configs/theme.config'

const initialState = {
    layout: themeConfig.layout,
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        setLayout: (state, action) => {
            state.layout = {
                ...state.layout,
                ...{ type: action.payload },
            }
        },
    },
})

export const {
    setLayout,
} = themeSlice.actions

export default themeSlice.reducer
