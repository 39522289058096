import history from "./history";
import { BrowserRouter } from "react-router-dom";
import Layout from "components/layout";
import { Provider } from "react-redux";
import store from "./stores";
import "assets/libs/@mdi/font/css/materialdesignicons.min.css";
import ScrollToTop from "components/core/scrollToTop";

function App() {
  return (
    <Provider store={store({})}>
      <BrowserRouter history={history}>
        <ScrollToTop />
        <Layout />
      </BrowserRouter>
    </Provider>
  );
}

export default App;
